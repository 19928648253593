/* Easings
   ========================================================================== */
.Work {
  font-family: "Roboto", sans-serif; }

.Work-title {
  margin-top: 0;
  margin-bottom: 25px; }

.Work-wrapItem:not(:last-child) {
  margin-bottom: 12px; }

/* Animations
   ========================================================================== */
.Work-title,
.Work-wrapItem {
  opacity: 0; }

.Work-title {
  -webkit-transform: translate3d(0, 40px, 0);
  transform: translate3d(0, 40px, 0); }

.Work-wrapItem {
  -webkit-transform: translate3d(0, 50px, 0);
  transform: translate3d(0, 50px, 0); }

.Work.is-visible .Work-title,
.Work.is-visible .Work-wrapItem {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: opacity 0.8s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: opacity 0.8s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.8s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1); }

.Work.is-visible .Work-wrapItem:nth-child(1) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s; }

.Work.is-visible .Work-wrapItem:nth-child(2) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s; }

.Work.is-visible .Work-wrapItem:nth-child(3) {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s; }

.Work.is-visible .Work-wrapItem:nth-child(4) {
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s; }

.Work.is-visible .Work-wrapItem:nth-child(5) {
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s; }

.Work.is-visible .Work-wrapItem:nth-child(6) {
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s; }

.Work.is-visible .Work-wrapItem:nth-child(7) {
  -webkit-transition-delay: 0.7s;
  transition-delay: 0.7s; }
