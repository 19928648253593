/* Easings
   ========================================================================== */
.Home {
  padding-top: 75px;
  padding-bottom: 100px;
  visibility: hidden;
  opacity: 0; }

.Home-body {
  margin-top: 165px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.Home-wrapWork {
  margin-right: 210px; }

@media (max-width: 900px) {
  .Home-body {
    display: block;
    margin-top: 120px; }
  .Home-wrapWork {
    margin-right: 0; }
  .Home-wrapContact {
    margin-top: 50px; } }

@media (max-width: 420px) {
  .Home {
    padding-top: 56px;
    padding-bottom: 86px; }
  .Home-body {
    margin-top: 100px; } }
