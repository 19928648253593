/* Easings
   ========================================================================== */
.Hero-wrapCanvas {
  -webkit-transform: translateX(-12px);
  transform: translateX(-12px);
  width: 510px;
  height: 160px; }

.Hero-subtitle {
  margin-top: 30px;
  margin-bottom: 0;
  font-weight: 400; }
  .Hero-subtitle span {
    display: block; }

/* Animations
   ========================================================================== */
.Hero-subtitle span {
  opacity: 0; }
  .Hero-subtitle span:first-child {
    -webkit-transform: translate3d(0, 100px, 0);
    transform: translate3d(0, 100px, 0); }
  .Hero-subtitle span:last-child {
    -webkit-transform: translate3d(0, 100px, 0);
    transform: translate3d(0, 100px, 0); }

.Hero.is-visible .Hero-subtitle span {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: opacity 0.8s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: opacity 0.8s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.8s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1); }
  .Hero.is-visible .Hero-subtitle span:last-child {
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s; }

/* Responsive
   ========================================================================== */
@media (max-width: 900px) {
  .Hero-subtitle {
    font-size: 36px; }
  .Hero-wrapCanvas {
    width: 400px;
    height: 115px;
    -webkit-transform: translateX(-16px);
    transform: translateX(-16px); } }

@media (max-width: 420px) {
  .Hero-wrapCanvas {
    width: 290px;
    height: 90px;
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px); }
  .Hero-subtitle {
    font-size: 27px; } }
