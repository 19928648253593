/* Easings
   ========================================================================== */
.Contact-title {
  margin-top: 0;
  margin-bottom: 25px; }

.Contact-content {
  display: block; }

.Contact-mail {
  cursor: pointer;
  position: relative; }

.Contact-mailTooltip {
  pointer-events: none;
  position: absolute;
  bottom: -26px;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 400; }

/* Animations
   ========================================================================== */
.Contact-title,
.Contact-body > span {
  opacity: 0; }

.Contact-title {
  -webkit-transform: translate3d(0, 40px, 0);
  transform: translate3d(0, 40px, 0); }

.Contact-body > span {
  -webkit-transform: translate3d(0, 50px, 0);
  transform: translate3d(0, 50px, 0); }

.Contact.is-visible .Contact-title,
.Contact.is-visible .Contact-body > span {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: opacity 0.8s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: opacity 0.8s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.8s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1); }

.Contact.is-visible .Contact-body > span {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s; }
  .Contact.is-visible .Contact-body > span:nth-child(2) {
    -webkit-transition-delay: 0.15s;
    transition-delay: 0.15s; }
